@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: Titillium Web, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}

@-moz-document url-prefix() {
  html {
    scroll-behavior: auto !important;
  }
}

a {
  text-decoration: none;
}

p {
  font-size: 14px;
}

* {
  box-sizing: border-box;
}

.form-group {
  margin-bottom: 1rem;
}

.table>thead {
  vertical-align: middle;
}

.unsub {
  max-width: 550px;
  padding-bottom: 0;
}

.validation {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 4vw;
}

.foot {
  border: none;
  display: flex;
  justify-content: center;
  margin: -10px 0 10px 0;
}

main {
  min-height: 100vh;
  margin-top: -88px;
  padding-top: 88px;
  padding-bottom: 200px;
}

@media all and (max-width: 525px) {
  main {
    padding-bottom: 300px;
  }
}

.display-linebreak {
  white-space: pre-line;
}

.offcanvas {
  width: 300px;
}

.modal-backdrop {
  z-index: 1040;
}

input {
  -webkit-appearance: none !important;
}

select {
  border-radius: 0 !important;
}

:root {
  --bright-yellow: rgba(255, 255, 0, 0.53);
  --bright-yellow-solid: rgba(255, 255, 120, 1);
  --cornflower-two: #7984f3;
  --cornflower-light: rgba(121, 132, 243, 0.27);
  --cornflower: rgba(121, 132, 243, 0.53);
  --tomato: #e24912;
  --tomato-mid: rgba(226,73,18,0.7);
  --tomato-light: rgba(226,73,18,0.45);
  --sickly-yellow: #d9db3e;
  --sickly-yellow-light: rgba(217,219,62,0.45);
  --cornflower-image: rgba(121, 132, 243, 0.45);
  --soft-blue: #62a2ef;
  --silver: #ebeded;
  --black: #000000;
  --warm-grey: #989898;
  --black-23: rgba(0, 0, 0, 0.23);
  --white: #f4f2f2;
  --white-two: #e5e5e5;
  --green: rgb(123, 237, 159);
  --orange: rgb(250, 152, 058);
}

/*** Banner ***/
.banner-header {
  background-image: url('/img/breeder-bandeau-interieur.jpg');
  height: 236px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  display: flex;
  align-items: center;
  background-position-y: -68px;
  background-position-x: right;
}

.banner-header h1 {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.1;
  text-align: left;
  color: #fff;
}

.banner-header h2 {
  font-size: 24px;
  text-align: left;
  color: #fff;
}

@media all and (max-width: 915px) {
  .banner-header {
    background-position-y: center;
  }
}

/*** End Banner ***/

.blue_bullet {
  list-style-type: none;
}

.blue_bullet li::before {
  content: url('/icons/icon-connect-left-violet.svg');
  margin-right: 4px;
  vertical-align: text-top;
}

/** Style global OAD **/
.oad-style {
  min-height: 70vh;
  /*width: calc(100% - 343px);*/
  max-width: 1200px;
  padding: 40px 20px;
}

.oad-style .title {
  margin: 25px 0;
  font-size: 34px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  color: #212529;
  display: flex;
  align-items: center;
  justify-content: center;
}

.oad-style h2 {
  margin-bottom: 36px;
  margin-top: 65px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #212529;
  display: flex;
  align-items: center;
  width: 100%;
}

.oad-style h2 div.sticker{
  width: 39px;
  height: 42px;
  background-image: url('/icons/bg-icon.svg');
  background-position: center;
  background-size: cover;
  font-size: 34px;
  text-align: center;
  margin-right: 14px;
}

.oad-style h2 div.sticker .chevron {
  padding-bottom: 5px;
  padding-left: 3px;
}

.oad-style h2 div.box{
  width: 32px;
  height: 32px;
  background-color: #e49a3b;;
  background-position: center;
  font-size: 20px;
  text-align: center;
  align-content: center;
  margin-right: 14px;
  border-radius: 4px;
  color: #ffffff;
}

.oad-style h2 div.box .chevron {
  padding-top: 3px;
  padding-left: 2px;
}

.oad-style h2 div.title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #212529;
  max-width: 80%;
}

.oad-style .bloc-text{
  margin-left: 18px;
  padding-left: 34px;
  border-left: solid 2px #f4f4f4;
}

.oad-style .errorCell input
{
  color: red;
}

.oad-style .error {
  text-align: center;
  color: red;
  font-weight: bold;
}

.oad-style .big {
  margin-bottom: -13px;
  margin-top: -11px;
}

.oad-style .small {
  margin-bottom: -11px;
  margin-top: -2px;
  font-size: 9px;
}

.oad-style p {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #5d6267;
}

.oad-style .btnContainer {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 74px;
}

.oad-style input::-webkit-outer-spin-button,
.oad-style input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.oad-style .result {
  display: flex;
  align-items: center;
}

.oad-style .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.oad-style .content .col {
  display: block;
  flex: none;
  max-width: 400px;
  width: 100%;
}

@media all and (max-width: 915px) {
  .oad-style {
    width: 100%;
    padding: 0 20px;
  }

  .oad-style h1 {
    margin-top: 20px;
  }

  .oad-style h3 {
    margin: 20px 0;
  }

  .oad-style .content {
    margin-bottom: 10px;
  }

  .oad-style .result {
    flex-wrap: wrap;
  }

  .oad-style .btnContainer {
    margin-bottom: 0;
  }
}

/* OAD style data table*/
.oad-style .data.table {
  text-align: center;
}
.oad-style .data.table th {
  font-size: 16px;
  line-height: 1.2;
}

.oad-style .data.table td {
  background-color: #f4f4f4;
  min-height: 43px;
}

.oad-style .data.table td, .oad-style .data.table th {
  position: relative;
  border: 2px solid #ffffff;
  vertical-align: middle;
}

.oad-style .data.table th:not(.head) {
  font-weight: normal;
}

.oad-style .data.table .head {
  background-color: #888da7;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #ffffff;
}

.oad-style .data.table .sel {
  padding: 0;
}

.oad-style .data.table .disable {
  opacity: 0.4;
}

.oad-style .data.table textarea,
.oad-style .data.table input,
.oad-style .data.table input:focus,
.oad-style .data.table select,
.oad-style .data.table select:focus {
  text-align: center;
  border: none;
  outline: none;
}

.oad-style .data.table input,
.oad-style .data.table textarea,
.oad-style .data.table select
{
  background-color: #f4f4f4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #5d6267;
  border-bottom: 2px solid #e1dede;
  width: 100%;
  /*max-width: 156px;*/
}

.oad-style .data.table select {
  padding-left: 13px;
}

.oad-style .data.table textarea {
  width: 100%;
  resize: none;
  overflow: hidden;
}

.oad-style .data.table input::placeholder {
  font-size: 16px;
  font-weight: bold;
  color: #a7a7a7;
}

.dataResult th {
    background-color: #4e5fb7;
    color: white;
}
.dataResult tfoot {
  background-color: #5d6267;
  color: white;
}

/* Firefox */
.oad-style .data.table input[type='number'] {
  -moz-appearance: textfield;
}

/*OAD sytle field*/
.oad-style .oad-field {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 13px;
  height: 80px;
}

.oad-style .oad-field .oad-field-form {
  display: flex;
  width: 100%;
}
.oad-style .oad-field .note {
  width: 100%;
  max-width: 364px;
  text-align: end;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #979797;
}

.oad-style .oad-field .note.error {
  color: red;
}

.oad-style .oad-field label {
  max-width: 219px;
  width: 100%;
  border: solid 2px #f4f4f4;
  border-radius: 15px 0 0 15px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 60px;
}

.oad-style .oad-field input,
.oad-style .oad-field select {
  max-width: 147px;
  width: 100%;
  background-color: #f4f4f4;
  text-align: center;
  border: none;
  padding: 5px;
  height: 60px;
}

/* End OAD style field table*/

/** End style global OAD **/

/** Start blue-banner-list **/

.blue-banner-list {
  height: 112px;
  margin: auto auto 30px;
  padding: 0 19px 0 24px;
  border-radius: 10px;
  background-image: linear-gradient(to bottom, #3d4da0, #4e5fb7);
  display: flex;
}

.blue-banner-list .item{
  display: flex;
  flex: 1 1 0;
  position: relative;
  height: 100%;
  align-items: center;
}

.blue-banner-list .number {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  color: #586118;
}

.blue-banner-list .item .number {
  position: absolute;
  left: 10px;
}

.blue-banner-list .text {
  font-size: 15px;
  line-height: 1.2;
  color: #c7d46a;
  margin-left: 7px;
  position: relative;
  min-width: 110px;
}

.blue-banner-list .white-line {
  margin-right: 10px;
  margin-left: 10px;
  border-right: 3px solid white;
  height: 100%;
}

.blue-banner-list .img {
  width: 31px;
  height: 34px;
}

  /** End blue-banner-list **/

/** Card **/
.card-style {
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: 0 5px 16px 0 rgba(33, 37, 41, 0.1);
  background-color: #fff;
  margin: 8px;
}
.card-style h3.card-style-title {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #4e5fb7;
}
.card-style p.card-style-text {
  font-size: 15px;
  line-height: 1.47;
  text-align: center;
  color: #212529;
}
/** End card **/

/** Tips **/
/*Hide for mobile*/
@media all and (max-width: 915px) {
  .hide-mobile {
    display: none;
  }
}
@media all and (min-width: 915px) {
  .hide-no-mobile {
    display: none;
  }
}

/** End Tips **/
